<template>
  <page-layout linkDefault="/profile" backButton="true" :title="user.username">
    <div
      class="
        bottom-2
        left-1
        right-1
        px-6
        pt-4
        pb-2
        flex
        justify-around
        items-center
      "
    >
      <div class="flex flex-row items-center justify-between space-x-14">
        <div>
          <p
            class="text-xs font-poppins font-semibold"
            :class="{ 'text-primary': followersTab }"
            @click="setFollowersTab"
          >
          {{followerUsers.length}}  Followers
          </p>
        </div>
        <div>
          <p
            class="text-xs font-poppins font-semibold"
            :class="{ 'text-primary': followingTab }"
            @click="setFollowingTab"
          >
            {{followingUsers.length}} Following
          </p>
        </div>
      </div>
    </div>
    <br />
    <div v-if="followingTab" class="flex flex-col">
      <div v-for="(_user, i) in followingUsers" :key="i">
        <div class="flex flex-row justify-between items-center">
          <Avatar class="ml-4 my-4" :user="_user" name university> </Avatar>
          <div class="w-40">
            <Button
              :key="update1"
              color="primary"
              :shape="'default'"
              class="ring-2 ring-primary px-4 pl-10 py-1"
              size="small"
              iconPosition="left"
              icon="user-plus"
              @click="toggleFollow(_user)"
            >
              {{ "following" }}</Button
            >
          </div>
        </div>
      </div>
    </div>

    <div v-if="followersTab" class="flex flex-col">
      <div v-for="(_user, i) in followerUsers" :key="i">
        <div class="flex flex-row justify-between items-center">
          <Avatar class="ml-4 my-4" :user="_user" name university> </Avatar>
          <div class="w-40">
            <Button
              color="primary"
              :key="update1"
              :shape="checkIfFollowed(_user) ? 'default' : 'ghost'"
              class="ring-2 ring-primary px-4 pl-10 py-1 w-40"
              size="small"
              iconPosition="left"
              icon="user-plus"
              @click="toggleFollow(_user)"
            >
              {{ checkIfFollowed(_user) ? "following" : "follow " }}</Button
            >
          </div>
        </div>
      </div>
    </div>
  </page-layout>
</template>

<script>
import Loading from "../../components/Loading.vue";
import pageLayout from "../../components/base/pageLayout.vue";
import Avatar from "../../components/Feed/Avatar.vue";
import Button from "../../components/Feed/Button.vue";
import { getFollowers, unfollow, follow } from "../../helpers/API/Feeds/User";
export default {
  components: { Avatar, Button, pageLayout, Loading },
  data() {
    return {
      user: null,
      loading: false,
      followingUsers: [],
      followerUsers: [],
      followersTab: true,
      followingTab: false,
      followersId:[],
      followingId:[],
      update: 0,
      update1: 0,
    };
  },
  created() {
    this.setUser();
  },
  methods: {
    setUser() {
      this.loading = true;
      this.user = this.$store.state.user;
      this.followersId= this.user.feed_user.followers;
      this.followingId= this.user.feed_user.following;
      // splitting name into firstname and lastname
      this.getUserss();
    },
    async getUserss() {
      await getFollowers(this.user.feed_user_id).then((data) => {
        this.followingUsers = data.following;
        this.followerUsers = data.followers;
      });
    },
    async toggleFollow(user) {
      let isFollowed = this.checkIfFollowed(user);
      if (isFollowed === true) {
        if (confirm(`Are you sure you want to unfollow ${user.name}`)) {
          let index = this.followingUsers.indexOf(user);
          let index1 =this.followingId.indexOf(user._id);
          await unfollow(this.user.feed_user_id, user._id).then(() => {
            this.followingUsers.splice(index, 1);
            this.followingId.splice(index1,1);
          });
          this.update1++;
          this.update++;
          
        }
      } else {
        if (confirm(`Are you sure you want to follow ${user.name}`)) {
          await follow(this.user.feed_user_id, user._id).then(() => {
            this.followingUsers.push(user);
            this.followingId.push(user._id);
          });
          this.update1++;
          this.update++;
        }
      }
    },
    checkIfFollowed(user) {
      if (this.followingId.indexOf(user._id) > -1) {
        return true;
      } else {
        return false;
      }
    },
    setFollowersTab() {
      this.followersTab = true;
      this.followingTab = false;
      this.update1++;
       this.update++;
    },
    setFollowingTab() {
      this.followingTab = true;
      this.followersTab = false;
      this.update1++;
       this.update++;
    },
  },
};
</script>
